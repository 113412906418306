import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { PlusCircleOutlined, MinusCircleOutlined, PaperClipOutlined, ProfileOutlined, DollarOutlined, ExclamationCircleOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons-vue';
import { defineComponent, ref, reactive, computed, onMounted } from 'vue';
import getFieldRules from '@/utils/fieldRules';
import { insertEvectionApply, selectCostCenterByParam } from '@/api/officeApi';
import moment from 'moment';
import { selectApprovalItem } from '@/api/material';
import Cookies from "js-cookie";
export default defineComponent({
  components: {
    PlusCircleOutlined,
    MinusCircleOutlined,
    PaperClipOutlined,
    ProfileOutlined,
    DollarOutlined,
    ExclamationCircleOutlined,
    DeleteOutlined,
    PlusOutlined
  },
  props: {
    applyVisible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['closeApplyVisible'],

  setup(props, context) {
    const formRef = ref();

    const closeApplyVisible = () => {
      formRef.value.resetFields();
      context.emit('closeApplyVisible', {
        isClose: false,
        state: 1
      });
    };

    const formState = reactive({
      // theme:"",
      // bourn:undefined,
      // evectionCause:undefined,
      // vehicle:undefined,
      // departureTime:undefined,
      returnTime: undefined,
      belongContract: undefined,
      repairReason: "",
      itemId: null,
      detailsList: [{
        bourn: null,
        evectionCause: null,
        vehicle: null,
        departureTime: null
      }]
    });
    const {
      validateTime
    } = getFieldRules();
    const rules = {
      // theme: [
      //   {
      //     required: true,
      //     message: '申请主题不能为空',
      //     trigger: 'blur',
      //   },
      //   {
      //     pattern: /^[^\s]*$/,
      //     message: '禁止输入空格',
      //     trigger: 'blur',
      //   }
      // ],
      bourn: [{
        required: true,
        message: '目的地不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      evectionCause: [{
        required: true,
        message: '出差事由不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      vehicle: [{
        required: true,
        message: '交通工具不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      departureTime: [{
        required: true,
        message: '出发日期不能为空',
        trigger: 'change',
        validator: validateTime
      }],
      returnTime: [{
        required: true,
        message: '返回日期不能为空',
        trigger: 'change',
        validator: validateTime
      }],
      repairReason: [{
        required: true,
        message: '补报出差申请理由不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      costName: [{
        required: true,
        message: '成本中心不能为空',
        trigger: 'change'
      }],
      itemId: [{
        required: true,
        message: '审批流程线不能为空',
        type: 'number',
        trigger: 'change'
      }]
    };
    const loading = ref(false); //提交

    const handleOk = () => {
      // console.log(formState)
      formRef.value.validate().then(async () => {
        let flag = formState.detailsList.findIndex(item => {
          return moment(item.departureTime).valueOf() > moment(formState.returnTime).valueOf();
        });

        if (flag >= 0) {
          _message.error('出发日期不能大于返回日期');

          return;
        } // if(moment(formState.departureTime).valueOf()>moment(formState.returnTime).valueOf()){
        //   message.error('出发日期不能大于返回日期')
        //   return
        // }


        const obj = {};
        Object.assign(obj, formState);
        obj.belongContract = obj.belongContract ? obj.belongContract : null;
        loading.value = true;
        let res = await insertEvectionApply(obj);

        if (res.code === 200) {
          _message.success(res.message);

          formRef.value.resetFields();
          context.emit('closeApplyVisible', {
            isClose: false,
            state: 2
          });
        } else {
          _message.error(res.message);

          loading.value = false;
        }

        loading.value = false;
      }).catch(error => {
        console.log('error', error);
      });
    };

    const disabledDate = current => {
      return current && current <= moment().subtract(1, 'days');
    };

    const handleAdd = () => {
      formState.time.push(undefined);
    };

    const onDelete = key => {
      formState.time.splice(key, 1);
    }; //成本中心查询


    const costCenterOptions = ref([]);

    const getCostCenter = async () => {
      let res = await selectCostCenterByParam({
        costName: null
      });

      if (res.code === 200) {
        costCenterOptions.value = [];
        if (res.data) res.data.forEach(item => {
          costCenterOptions.value.push({
            value: item.costName,
            label: item.costName
          });
        });
      } else {
        _message.error(res.message);
      }
    }; // 是否显示补报出差理由


    const isShowRepairReason = computed(() => {
      let flag = -1;
      flag = formState.detailsList.findIndex(item => {
        return moment(item.departureTime).valueOf() < moment(moment().format('YYYY-MM-DD')).valueOf();
      });

      if (flag == -1) {
        formState.repairReason = null;
        return false;
      } else {
        return true;
      }
    }); //所属项目查询

    const projectOptions = ref([]);

    const getApprovalItem = async () => {
      const param = {
        type: 10,
        belongProject: Number(Cookies.get("belongProject"))
      };
      let res = await selectApprovalItem(param);

      if (res.code === 200) {
        projectOptions.value = [];

        if (res.data.approvalItems && res.data.approvalItems.length) {
          res.data.approvalItems.forEach(item => {
            projectOptions.value.push({
              value: item.id,
              label: item.itemName
            });
          });
        }
      } else {
        _message.error(res.message);
      }
    };

    const addList = () => {
      formState.detailsList.push({
        bourn: null,
        evectionCause: null,
        vehicle: null,
        departureTime: null
      });
    };

    const deleteList = index => {
      if (formState.detailsList.length === 1) {
        _message.error('出差明细不能全部删除!');

        return;
      }

      formState.detailsList.splice(index, 1);
    };

    onMounted(() => {
      getCostCenter();
      getApprovalItem();
    });
    return {
      closeApplyVisible,
      formRef,
      formState,
      rules,
      handleOk,
      loading,
      disabledDate,
      handleAdd,
      onDelete,
      validateTime,
      costCenterOptions,
      isShowRepairReason,
      projectOptions,
      addList,
      deleteList
    };
  }

});